<template>
  <div class="app-container">

    <div>
      <el-tabs v-model="activeName" @tab-click="getList">
          <el-tab-pane label="全部" name="2"></el-tab-pane>
          <el-tab-pane label="已分配" name="1"></el-tab-pane>

      </el-tabs>
    </div>

    <div class="bookkeeping-table">
      <el-table :data="list" :height="contentStyleObj" style="width: 100%" border @selection-change="handleSelectionChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" align="center" width="50" />
      
        <el-table-column label="代理记账公司名称" align="center" width="250">
          <template #default="scope">
            <span>{{ scope.row.org_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="公司名称" align="center" width="250">
          <template #default="scope">
            <span @click="gotoAccountBookSys(scope.row)" style="font-size:14px;cursor: pointer;">{{ scope.row.com_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="授权人" align="center" width="90">
          <template #default="scope">
            <span>{{ scope.row.user_name}}</span>
          </template>
        </el-table-column>

        <el-table-column label="请求时间" align="center" width="100">
          <template #default="scope">
            <span>{{$parseTime(scope.row.apply_at, "{y}-{m}-{d}")}}</span>
          </template>
        </el-table-column>
    
        <el-table-column label="操作" align="center" width="330" class-name="small-padding fixed-width">
          <template #default="scope">
            <el-button size="small" @click="gotoAccountBookSys(scope.row)" type="success">进入账簿</el-button>
            <el-button type="danger" plain size="small" @click="empower2(scope.row)">取消协助</el-button>
            <!-- <el-button type="success" plain size="small" @click="InTax(scope.row.comId)">进入税局</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
  
  </div>
</template>

<script>
import { getRemoteHelperList,helpStart,helpOver } from "@/api/help";
import { loginTaxInfo } from "@/api/company"
import axios from 'axios'


// import {switchAccountBook} from "@/api/crm";
// import { getRemoteHelperList,helpStart,helpOver,loginTaxInfo } from "@/api/ea";
// import axios from 'axios'
// import waves from "@/directive/waves"; // Waves directive
// import { parseTime, currentAccountPeriod } from "@/utils";
// import Pagination from "@/components/Pagination"; // Secondary package based on el-pagination

export default {
  name: "empower",
  data() {
    return {
      activeName: "2",
      list:[],
    };
  },
  created() {
    this.contentStyleObj= this.$getHeight(180)
    this.getList()
  },
  methods: {
    getList(){
      getRemoteHelperList({type:this.activeName*1}).then(res=>{
        if(res.data.msg == "success"){
          this.list = res.data.data
        }
      })
    },
    gotoAccountBookSys(row) {
      console.log(row)
      helpStart({comId: row.comId}).then(res=>{
        if (res.data.msg == "success") {
          // let time = new Date().getTime();
          // localStorage.setItem("time", time);
          // localStorage.setItem("curSys", "accountBook");
          // localStorage.setItem("comName", row.com_name);
          // sessionStorage.setItem("toEaFromView", '/company-manage/empower');
          // // location.reload()
          // window.location.href = window.location.origin;
          this.$goEtax(row.comId)
        }
      })
    },
    empower2(row){
      this.$confirm('确认取消远程协助?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        helpOver({comId:row.comId}).then(res=>{
          if (res.data.msg == "success") {
            this.$message({
              message: "已取消",
              type: "success"
            });
            this.getList()
          }
        })
      })
    },
    InTax(id){
      this.$qzfMessage('打开中请稍后...',1);
      let that = this
      loginTaxInfo({id:id}).then(res=>{
        let param = res.data.data.info
        axios.post('http://localhost:9111/loginTax/autoLoginTax',param).then(res=>{
          if(res.data.msg != "success"){
            that.$alert(res.data.msg, '提示', {
              confirmButtonText: '确定',
            });
          } 

        }).catch(function (e) {
          that.$alert('请先安装客户端', '提示', {
            confirmButtonText: '确定',
          });
          return
        });

      })
    }
  //   gotoAccountBookSys(row) {
  //     console.log(row)
  //     helpStart({comId: row.comId}).then(e=>{
  //       switchAccountBook({ comId: row.comId }).then(res => {
  //         if (res.data.msg == "success") {
  //           let time = new Date().getTime();
  //           localStorage.setItem("time", time);
  //           localStorage.setItem("curSys", "accountBook");
  //           localStorage.setItem("comName", row.com_name);
  //           sessionStorage.setItem("toEaFromView", '/company-manage/empower');
  //           // location.reload()
  //           window.location.href = window.location.origin;
  //         }
  //       });
  //     })
  //   },
  //   gotax(row){
  //     this.$message('打开中请稍后...');
  //     let that = this
  //     loginTaxInfo({id: row.comId}).then(res=>{
  //       let param = res.data.data.info
  //       // autoLoginTax(param).catch(function (e) {
  //       //     that.$alert('请先安装客户端', '提示', {
  //       //       confirmButtonText: '确定',
  //       //     });
  //       //     return
  //       // });
  //       axios.post('http://localhost:9111/loginTax/autoLoginTax',param).then(res=>{
  //         if(res.data.msg != "success"){
  //           that.$alert(res.data.msg, '提示', {
  //             confirmButtonText: '确定',
  //           });
  //         } 
  //       }).catch(function (e) {
  //         that.$alert('请先安装客户端', '提示', {
  //           confirmButtonText: '确定',
  //         });
  //         return
  //       });
  //     })
  //   },
  }
};
</script>
<style lang="scss" scoped>
</style>
